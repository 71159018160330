import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [{
    path: '/',
    name: 'faceBook',
    component: () =>
        import ('@/views/faceBook')
}]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router